import { Button, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC, Fragment, useEffect, useState } from 'react';

import { ButtonLearnMore } from '@components/Button';
import { News } from '@models/post';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { decodeHtml, formatDateByLocale } from 'utils';

const { Paragraph } = Typography;

export const CardNews: FC<News> = ({
  title,
  categories,
  thumbnail,
  link,
  subtitle,
  readingTime,
  disabled = false,
  isShowReadMoreIcon = true,
  date,
}) => {
  const { t } = useTranslation();
  const [newTitle, setNewTitle] = useState('');

  const router = useRouter();
  const { locale } = router;

  const NUMBER_OF_CATEGORY_SHOW = 2;

  useEffect(() => {
    setNewTitle(title);
  }, [title, subtitle]);

  return (
    <Link href={`/${link}`}>
      <a
        className={classNames('card-news', { 'card-news-blog': subtitle })}
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <div className="card-news__thumbnail">
          {thumbnail && <Image src={thumbnail} alt={title} layout="fill" className="image" quality={100} />}
        </div>
        <div className="card-news__meta">
          <span className="card-news__category highlight">
            {categories.map((item, index) => (
              <Fragment key={index}>
                {index < NUMBER_OF_CATEGORY_SHOW && <span key={index}>{item}</span>}
                {categories.length > NUMBER_OF_CATEGORY_SHOW && index < NUMBER_OF_CATEGORY_SHOW - 1 && <span>, </span>}
              </Fragment>
            ))}
            {categories.length > NUMBER_OF_CATEGORY_SHOW && (
              <Tooltip
                title={
                  <ul>
                    {categories.map((item, index) => index >= NUMBER_OF_CATEGORY_SHOW && <li key={index}>{item}</li>)}
                  </ul>
                }
                trigger={['hover', 'click']}
              >
                <span>{` +${categories.length - NUMBER_OF_CATEGORY_SHOW}`}</span>
              </Tooltip>
            )}
          </span>
          <span className="divider">|</span>
          <span className="time_reading">
            {readingTime} {t('news.txtMinsRead')}
          </span>
        </div>
        <Paragraph className="card-news__title" ellipsis={{ rows: 2 }}>
          {decodeHtml(newTitle)}
        </Paragraph>
        <div className="card-news__issue">
          <span>{formatDateByLocale(date, locale)}</span>

          <ButtonLearnMore
            icon={
              isShowReadMoreIcon ? (
                <div className="wrap-icon">
                  <Image src="/icons/right-arrow-blue-2.svg" alt="arrow-icon" width={24} height={24} />
                </div>
              ) : null
            }
            text={t('common.textReadMore')}
          />
        </div>
        <Button className="card-news__button">{t('common.textReadMore')}</Button>
      </a>
    </Link>
  );
};
