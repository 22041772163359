import { Heading } from '@components/Layout/Service';
import ModalVideo from '@components/ModalVideo';
import { Carousel, Grid, Space } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useState } from 'react';

const ourClientsSayData = [
  {
    avatar: '/images/clients/mrkazuya.jpg',
    clientSay: 'clients.being_welcomed',
    clientName: 'clients.mr_kazuya',
    clientRole: 'clients.ceo',
    clientRoleAt: 'clients.sukusuku',
    video: 'https://www.youtube.com/watch?v=-v66TF71l40',
  },
];

const { useBreakpoint } = Grid;

const OurClientsSay = () => {
  const { t } = useTranslation();
  const { xxl, sm } = useBreakpoint();

  const [modalVideo, setModalVideo] = useState({
    playing: false,
    video: '',
  });

  const handleModalVideo = (client: any) => {
    setModalVideo({
      playing: !modalVideo.playing,
      video: client.video,
    });
  };

  const handleSetPlayingVideo = (status: boolean) =>
    setModalVideo({ playing: status, video: '' });

  const renderClientData = () =>
    ourClientsSayData.map((client, index: number) => (
      <div key={index} className="client-wrapper">
        <div className="client-avatar">
          <Image
            src={client.avatar}
            alt={client.clientName}
            layout="fill"
            quality={100}
          />
        </div>

        <div className="client-information">
          <div className="icon-quote">
            <Image
              src="/icons/icon-quote-v3.svg"
              alt="quote icon"
              layout="fill"
            />
          </div>

          <p>{t(client.clientSay)}</p>

          <div className="name">{t(client.clientName)}</div>

          <Space size={sm ? 14 : 6}>
            <span> {t(client.clientRole)}</span>
            <div className="small-circle" />
            <span> {t(client.clientRoleAt)}</span>
          </Space>
        </div>

        <div className="youtube-icon">
          <Image
            src="/icons/youtube.svg"
            alt="quote-icon"
            width={xxl ? 80 : sm ? 64 : 32}
            height={xxl ? 60 : sm ? 48 : 24}
            quality={100}
            onClick={() => handleModalVideo(client)}
          />
        </div>
      </div>
    ));

  return (
    <div className="our-clients-say">
      <Heading
        title={t('v3_homepage.what_our_clients_say')}
        subtitle={t('v3_homepage.we_take_great_pride')}
        titleLevel={2}
      />

      <Carousel
        arrows
        dots={false}
        slidesToShow={1}
        slidesToScroll={1}
        infinite={false}
        nextArrow={
          <button>
            <Image
              src="/icons/right-slick-arrow.svg"
              width={8}
              height={12}
              alt="next-arrow"
            />
          </button>
        }
        prevArrow={
          <button>
            <Image
              src="/icons/left-slick-arrow.svg"
              width={8}
              height={12}
              alt="previous-arrow"
            />
          </button>
        }
      >
        {renderClientData()}
      </Carousel>

      <ModalVideo
        videoUrl={modalVideo.video}
        isVisibleModalVideo={modalVideo.playing}
        setIsVisibleModalVideo={handleSetPlayingVideo}
      />
    </div>
  );
};

export default OurClientsSay;
