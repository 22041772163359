import { Grid } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import LazyLoad from 'react-lazy-load';

import { portfolioAPI } from '@apiClient/post-api';

import 'swiper/css';
import { Heading } from '@components/Layout/Service';
import MyCarousel from '@components/_v2/MyCarousel';
import striptags from 'striptags';

const { useBreakpoint } = Grid;

type PropsOurPortfolio = {
  isUnique?: boolean;
  listServices?: any;
  title?: string;
  subtitle?: string;
  visibleSwipper?: boolean;
  portfolioService?: string;
  defaultServices?: string;
  totalPortfolio?: number;
};

const servicesDefault = [
  {
    key: 'web-development',
    title: 'home.txtWebAndWebApp',
  },
  {
    key: 'mobile-development',
    title: 'home.txtMobileAppNew',
  },
  {
    key: 'blockchain',
    title: 'home.txtBlockchain',
  },
  {
    key: 'web3-game',
    title: 'common.txtWeb3Game',
  },
  {
    key: 'artificial-intelligence',
    title: 'home.txtArtificial',
  },
  {
    key: 'all',
    title: 'common.textAll',
    className: 'button-all',
  },
];

export const OurPortfolioV2: FC<any> = ({
  isUnique = false,
  listServices = [],
  title,
  subtitle,
  visibleSwipper = true,
  portfolioService = null,
  defaultServices = 'web-development',
  totalPortfolio = 3,
}: PropsOurPortfolio) => {
  const { t } = useTranslation();
  const { xs, md } = useBreakpoint();
  const router = useRouter();
  const [activeService, setActiveService] = useState(listServices[0]?.key || defaultServices);
  const [portfolios, setPortfolios] = useState([]);
  const [listPortfolios, setListPortfolios] = useState([
    {
      thumbnail: '',
      subtitle: '',
      title: '',
      categories: '',
      link: '',
      description: '',
    },
  ]) as any;
  const [isVisibleCarousel, setIsVisibleCarousel] = useState(false);
  const { locale } = useRouter();

  const handleChangeService = (service) => () => {
    if (service === 'all') {
      router.push('/portfolios');
      return;
    }

    let newListPortfolio = [];
    if (!isUnique) {
      newListPortfolio = portfolios
        ?.filter((item) =>
          item.categories?.some((category) => {
            return !service || service === category.slug;
          }),
        )
        ?.filter((_, index) => index <= (totalPortfolio || 3));
    } else {
      newListPortfolio = portfolios.filter((val) => val?.link === service);
    }

    setListPortfolios(newListPortfolio);
    setActiveService(service);
  };

  const getPortfolio = async (locale) => {
    const portfolios = await portfolioAPI
      .getList({
        params: {
          language: locale,
          category: portfolioService || null,
        },
      })
      .then(function (response) {
        return response.data.map((item) => ({
          thumbnail: item.images.full,
          subtitle: item.subtitle,
          title: item.project_name,
          categories: item.categories,
          link: item.post_name,
          description: item?.project_excerpt || item.excerpt,
        }));
      });

    setPortfolios(portfolios);
  };

  const handleCarouselVisible = () => {
    setIsVisibleCarousel(true);
  };

  useEffect(() => {
    if (portfolios?.length) {
      handleChangeService(activeService)();
    }
  }, [portfolios]);

  useEffect(() => {
    if (isVisibleCarousel) {
      getPortfolio(locale);
    }
  }, [locale, isVisibleCarousel]);

  return (
    <div className="our-portfolio-v2">
      <div className="container">
        <Heading
          title={title || t('home.titleOurPortfolio')}
          titleLevel={2}
          subtitle={
            md
              ? subtitle || t('home.newDescriptionOurPortfolio')
              : striptags(subtitle || t('home.newDescriptionOurPortfolio'))
          }
        />
        {visibleSwipper && (
          <Swiper
            key={`swiper-key-${xs}`}
            spaceBetween={16}
            breakpoints={{
              240: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 5,
              },
            }}
            allowTouchMove={xs}
          >
            {(listServices?.length > 0 ? listServices : servicesDefault).map((item) => (
              <SwiperSlide
                key={item.key}
                className={classNames(
                  {
                    'button-active': item.key === activeService,
                  },
                  item?.className,
                )}
                onClick={handleChangeService(item.key)}
              >
                {t(item.title)}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <LazyLoad height={md ? 560 : 576} offset={500} onContentVisible={handleCarouselVisible}>
          <MyCarousel
            portfolios={listPortfolios}
            hasContainer={false}
            altSchema={['subtitle', 'title']}
            settings={{ dots: true, autoplay: true }}
            limitPortfolio={totalPortfolio}
          />
        </LazyLoad>
      </div>
    </div>
  );
};
