import { ButtonPrimary } from '@components/Button';
import { Heading } from '@components/Layout/Service';
import { PropsOurSolution } from '@models/white-label';
import { ButtonPlayIcon } from '@public/icons';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { scroller } from 'react-scroll';

const OurSolution = ({
  title,
  subtitle,
  url,
  isShowButton = true,
  iconPlay,
  playing = false,
  isShowText = false,
}: PropsOurSolution) => {
  const { t } = useTranslation();
  const [startVideo, setStartVideo] = useState<boolean>(false);

  const handleScroll = () => {
    scroller.scrollTo('contact-us-form', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -150,
    });
  };

  return (
    <div className="our-solution">
      <Heading title={title} subtitle={subtitle} />
      <div className={classNames({ 'show-background': startVideo })}>
        <ReactPlayer
          url={url}
          playing={playing}
          controls
          className="react-player"
          playIcon={iconPlay || <ButtonPlayIcon />}
          width="100%"
          id="player"
          // light={light}
          onStart={() => setStartVideo(true)}
        />
      </div>
      {isShowButton && (
        <ButtonPrimary onClick={handleScroll} className="btn-learn-more">
          {t('white-label.txtBookLive')}
        </ButtonPrimary>
      )}
      {isShowText && !startVideo && (
        <div className="our-solution__text">
          <div className="our-solution__title">{t('game-fi.txtOurWeb3')}</div>
          <div className="our-solution__subtitle">
            {t('game-fi.descOurWeb3')}
          </div>
        </div>
      )}
    </div>
  );
};

export default OurSolution;
