import { Carousel } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { CardNews } from '@components/CardNews';
import { Title } from '@components/Title';
import { News as NewsInterface } from '@models/index';
interface TechInsights {
  title?: string;
  posts: [NewsInterface];
}

const SLIDE_TO_SHOW = 3;
const SLIDE_TO_SHOW_MOBILE = 1;

const TechInsights = ({ posts }: TechInsights) => {
  const { t } = useTranslation();

  return (
    <div className="tech-insights">
      <div className="container">
        <Title title={t('home.titleNews')} />

        <Carousel
          arrows
          dots={false}
          slidesToShow={SLIDE_TO_SHOW}
          slidesToScroll={1}
          infinite={false}
          nextArrow={
            <button>
              <Image
                src="/icons/right-slick-arrow.svg"
                width={8}
                height={12}
                alt="next-arrow"
              />
            </button>
          }
          prevArrow={
            <button>
              <Image
                src="/icons/left-slick-arrow.svg"
                width={8}
                height={12}
                alt="prev-arrow"
              />
            </button>
          }
          responsive={[
            {
              breakpoint: 576,
              settings: {
                slidesToShow: SLIDE_TO_SHOW_MOBILE,
              },
            },
          ]}
        >
          {posts.map((item, index) => (
            <CardNews {...item} key={index} isShowReadMoreIcon />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TechInsights;
