import React, { useState } from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';
import OurSolution from '@components/Layout/White-Label/OurSolution';

interface IModalVideo {
  isVisibleModalVideo: any;
  setIsVisibleModalVideo: any;
  videoUrl?: string;
}

const ModalVideo = ({
  isVisibleModalVideo,
  setIsVisibleModalVideo,
  videoUrl,
}: IModalVideo) => {
  const [playing, setPlaying] = useState(true);

  const handleClose = () => {
    setPlaying(false);
    setTimeout(() => {
      setIsVisibleModalVideo(false);
    }, 10);
    setTimeout(() => {
      setPlaying(true);
    }, 200);
  };

  return (
    <div className="modals">
      <Modal
        centered
        visible={isVisibleModalVideo}
        footer={null}
        className={classNames('modal-video')}
        onCancel={handleClose}
      >
        <div className="button-close">
          <img
            src="/icons/icon-close-white.png"
            alt="icon close"
            onClick={handleClose}
          />
        </div>

        <OurSolution
          url={videoUrl || 'https://www.youtube.com/watch?v=_Gf_2AvyqTQ'}
          light="/images/image-intro.png"
          isShowButton={false}
          iconPlay={
            <img
              src="/icons/icon-play.png"
              alt="icon-play"
              className="icon-play"
            />
          }
          playing={playing}
        />
      </Modal>
    </div>
  );
};

export default ModalVideo;
