import Image from 'next/image';
import { useEffect, useState } from 'react';

import Avex from 'public/images/avex-logo.webp';
import United from 'public/images/united-logo.webp';
import SocialWire from 'public/images/social-wire-logo.svg';
import RevenYou from 'public/images/revenyou-logo.webp';
import TXT from 'public/images/ttx-logo.svg';
import Koindex from 'public/images/koindex-logo.webp';
import Delex from 'public/images/delex-logo.svg';
import StartupStudio from 'public/images/startup-studio-logo.webp';
import Oddiyana from 'public/images/oddyya-logo.webp';
import NFT3A from 'public/images/nft-logo.webp';
import AIT from 'public/images/ait-logo.webp';
import R from 'public/images/r-logo.webp';
import Spidersilk from 'public/images/spider-silk-logo.webp';
import Early from 'public/images/early-logo.webp';
import Nebula from 'public/images/nebula-logo.svg';
import BlockchainJapan from 'public/images/blockchain-japan.webp';

const partners1 = [
  { name: 'Avex', logo: Avex },
  { name: 'United', logo: United },
  { name: 'SocialWire', logo: SocialWire },
  { name: 'Reven You', logo: RevenYou },
  { name: 'TTX', logo: TXT },
  { name: 'Koindex', logo: Koindex },
  { name: 'DLEX', logo: Delex },
  { name: 'Startup', logo: StartupStudio },
];

const partners2 = [
  { name: 'Oddiyana', logo: Oddiyana },
  { name: 'NFT3A', logo: NFT3A },
  { name: 'AIT', logo: AIT },
  { name: 'R', logo: R },
  { name: 'Spidersilk', logo: Spidersilk },
  { name: 'Early Works', logo: Early },
  { name: 'Nbula', logo: Nebula },
  { name: 'Blockchain Japan LTD', logo: BlockchainJapan },
];

const OrganizationsV2 = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const totalHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const percentage = (scrollPosition / totalHeight) * 100;
    setScrollPercentage(percentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="organization-v2">
      <div className="organization-v2__container">
        <div className="organization-v2__body">
          <div
            className="organization-v2__body--partners1"
            style={{ transform: `translate(${scrollPercentage}%)` }}
          >
            {partners1.map(({ name, logo }) => (
              <div key={name} className="item">
                <div className="item__container">
                  <Image
                    src={logo}
                    alt={name}
                    layout="fill"
                    objectFit="contain"
                    quality={100}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            className="organization-v2__body--partners2"
            style={{ transform: `translate(-${scrollPercentage}%)` }}
          >
            {partners2.map(({ name, logo }) => (
              <div key={name} className="item">
                <div className="item__container">
                  <Image
                    src={logo}
                    alt={name}
                    layout="fill"
                    objectFit="contain"
                    quality={100}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationsV2;
