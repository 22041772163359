import { Col, Grid, Row } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';

interface InsightInterface {
  listBlogHaveEbook?: any;
  className?: string;
  iconDownload?: string;
  showBackground?: boolean;
}

const { useBreakpoint } = Grid;

const Insight = ({
  className,
  listBlogHaveEbook,
  iconDownload,
  showBackground,
}: InsightInterface) => {
  const { t } = useTranslation();
  const { xxl } = useBreakpoint();

  return (
    <div className={classNames('section-insight', className)}>
      {listBlogHaveEbook
        .filter((e, idx) => idx < 1)
        .map((blog, i) => (
          <Row key={i}>
            <Col xs={24} sm={14}>
              <div className="section-insight__left">
                <Link href={`/${blog.link}`}>
                  <a>
                    <div
                      className="section-insight__title"
                      dangerouslySetInnerHTML={{ __html: blog?.title }}
                    />
                  </a>
                </Link>
                <div
                  className="section-insight__desc"
                  dangerouslySetInnerHTML={{ __html: blog?.subtitle }}
                />
                <div className="section-download">
                  <Link href={blog?.ebook}>
                    <a target="__blank" className="button-down">
                      <Image
                        src={iconDownload || '/icons/icon-download.svg'}
                        alt="icon-download"
                        width={24}
                        height={22}
                      />
                      <span className="text">{t('news.txtDownNow')}</span>
                    </a>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={10}>
              <div className="section-insight__right">
                <Image
                  src={blog?.ebook_thumbnail}
                  alt="thumbnail-ebook"
                  width={xxl ? 362 : 298}
                  height={xxl ? 362 : 298}
                />

                {showBackground && (
                  <div className="outsourcing-background">
                    <Image
                      src="/images/think-outsourcing.png"
                      alt="Outsourcing-bg"
                      className="outsourcing-background"
                      layout="fill"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        ))}
    </div>
  );
};
export default Insight;
