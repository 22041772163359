import { Heading } from '@components/Layout/Service';
import { Grid, Tabs } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useState } from 'react';
import Link from 'next/link';

const ourServicesAndProductsItems = [
  {
    key: 'blockchain',
    title: 'service.readyMakeSolutions',
    text: 'service.we_offer_comprehensive',
    items: [
      {
        name: 'service.txtPublicBlockchain',
        href: '/public-blockchain-development',
      },
      {
        name: 'tag.private_blockchain',
        href: '/private-blockchain-development',
      },
      { name: 'service.txtSmartContract', href: '/smart-contract-development' },
      {
        name: 'service.txtAssetTokenization',
        href: '/tokenization-development',
      },
      {
        name: 'service.txtDecentralizedExchange',
        href: '/decentralized-cryptocurrency-exchange-development',
      },
      { name: 'tag.nft_marketplace', href: '/white-label-nft-marketplace' },
      { name: 'common.txtWeb3Game', href: '/web3-game-development' },
      { name: 'service.txtCryptoWallet', href: '/white-label-crypto-wallet' },
      {
        name: 'service.txtTokenVesting',
        href: '/white-label-token-vesting-portal',
      },
      { name: 'service.txtDecentralizedApp', href: '/dapp-development' },
    ],
    ai: {
      title: 'service.serviceAI',
      items: [
        {
          name: 'service.ai_chatbot',
          href: '/ai-development',
        },
        {
          name: 'service.video_analytics',
          href: '/ai-development',
        },
        {
          name: 'service.smart_surveillance_camera',
          href: '/ai-development',
        },
        {
          name: 'service.video_restoration',
          href: '/ai-development',
        },
      ],
    },
    web3: {
      title: 'common.txtWeb3',
      items: [
        { name: 'service.txtSmartContract', href: '/smart-contract-development' },
        { name: 'service.txtDecentralizedApp', href: '/dapp-development' },
        { name: 'common.txtWeb3Game', href: '/web3-game-development' },
        { name: 'tag.nft_marketplace', href: '/white-label-nft-marketplace' },
        {
          name: 'service.txtDecentralizedExchange',
          href: '/decentralized-cryptocurrency-exchange-development',
        },
        {
          name: 'service.txtCentralizedExchange',
          href: '/centralized-cryptocurrency-exchange-development',
        },

        { name: 'service.txtCryptoWallet', href: '/white-label-crypto-wallet' },
        { name: 'service.txtCryptoStaking', href: '/defi-staking-platform-development' },

        {
          name: 'service.txtAssetTokenization',
          href: '/tokenization-development',
        },
        {
          name: 'service.txtTokenVesting',
          href: '/white-label-token-vesting-portal',
        },
      ],
    },
    live_stream: {
      title: 'service.live_streaming',
      items: [
        { name: 'service.social_platform', href: '' },
        { name: 'service.elearning_platform', href: '' },
      ],
    },
    image: '/images/services/service_blockchain.jpg',
    video: '',
  },
  {
    key: 'development',
    title: 'service.txtCustomSoftwareDevelopment',
    text: 'service.we_help_you_strategize',
    items: [
      {
        name: 'service.txtCustomSoftwareDevelopment',
        href: '/services/custom-software-development',
      },
      {
        name: 'web.txtWebsiteWebApp',
        href: '/services/web-development',
      },
      {
        name: 'home.txtMobileAppDevelopment',
        href: '/services/mobile-app-development',
      },
      {
        name: 'service.txtDigitalProductDesign',
        href: '/services/digital-product-design',
      },
      {
        name: 'service.txtQualityAssuranceTesting',
        href: '/services/software-testing-and-quality-assurance',
      },
      { name: 'service.txtManagedServices', href: '/' },
      {
        name: 'service.textLegacyMigration',
        href: '/services/legacy-migration',
      },
    ],
    image: '/images/services/service_development.jpg',
    video: '',
  },
  {
    key: 'emergingTechnologies',
    title: 'service.advanced_technology',
    text: 'service.we_turn_culting_edge',
    items: [
      { name: 'tag.blockchain', href: '/services/blockchain-development' },
      { name: 'common.txtAI', href: '/services/ai-development' },
      { name: 'service.txtCloud', href: '/services/cloud-development' },
      { name: 'service.txtARVR', href: '/services/ar-vr-app-development' },
      { name: 'service.txtIOT', href: '/services/iot-software-development' },
    ],
    image: '/images/services/service_emerging.jpg',
    video: '',
  },
  {
    key: 'staff',
    title: 'service.staffing_services',
    text: 'service.we_empower_your_development',
    items: [
      {
        name: 'service.txtDedicatedTeam',
        href: '/services/dedicated-development-team',
      },
      {
        name: 'dedicated_development.staff_augmentation',
        href: '/services/it-staff-augmentation',
      },
    ],
    image: '/images/services/service_staffaugmentation.jpg',
    video: '',
  },
];

const { useBreakpoint } = Grid;

const OurServicesV2 = () => {
  const { t } = useTranslation();
  const { sm } = useBreakpoint();

  const [currentTab, setCurrentTab] = useState(ourServicesAndProductsItems[0].key);

  const handleChangeTab = (currentTab: string) => setCurrentTab(currentTab);

  return (
    <div className="our-services-v2">
      <div className="our-services-v2__container">
        <Heading
          title={t('home.txtOurServiceAndOfferings')}
          titleLevel={2}
          subtitle={t('home.txtOurServiceAndProductsDescription')}
        />

        <Tabs className="our-services-v2__body" onChange={handleChangeTab} defaultActiveKey={currentTab}>
          {ourServicesAndProductsItems.map((data) => (
            <Tabs.TabPane tab={t(data.title)} key={data.key}>
              <div className="service-wrapper">
                <div className="service-wrapper__left">
                  <p className="text">{t(data.text)}</p>
                  {!data.ai && !data.web3 && !data.live_stream ? (
                    <ul className={`service-item ${data.key}`}>
                      {data.items.map((item, index: number) => (
                        <li key={index}>
                          <Link href={item.href}>{t(item.name)}</Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="list-wrapper">
                      <div className="left">
                        <ul className={`service-item ${data.key}`}>
                          <h3>{t(data.ai.title)}</h3>
                          {data.ai.items.map((item, index: number) => (
                            <li key={index}>
                              <Link href={item.href}>{t(item.name)}</Link>
                            </li>
                          ))}
                        </ul>

                        <ul className={`service-item ${data.key}`}>
                          <h3>{t(data.live_stream.title)}</h3>
                          {data.live_stream.items.map((item, index: number) => (
                            <li key={index}>
                              <Link href={item.href}>{t(item.name)}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="right">
                        <ul className={`service-item ${data.key}`}>
                          <h3>{t(data.web3.title)}</h3>
                          {data.web3.items.map((item, index: number) => (
                            <li key={index}>
                              <Link href={item.href}>{t(item.name)}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                <div className="service-wrapper__right">
                  {!sm || !data?.video ? (
                    <Image src={data.image} alt={data.title} layout="fill" objectFit="contain" quality={100} />
                  ) : (
                    <video autoPlay muted loop playsInline>
                      <source src={data.video} type="video/mp4" />
                    </video>
                  )}
                </div>
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default OurServicesV2;
