import { Carousel, Grid, Skeleton } from 'antd';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import LazyLoad from 'react-lazy-load';

import { LIMIT_PORTFOLIOS } from '@common/constant';
import { ButtonLearnMore } from '@components/Button';
import { Heading } from '@components/Layout/Service';
import { PropTypeMyCarousel } from '@models/carousel';
import { useTranslation } from 'next-i18next';

const { useBreakpoint } = Grid;

const MyCarousel = ({
  portfolios,
  title,
  subtitle,
  settings,
  hasContainer = true,
  altSchema = ['title'],
  limitPortfolio = LIMIT_PORTFOLIOS,
}: PropTypeMyCarousel) => {
  const { md } = useBreakpoint();
  const { t } = useTranslation();
  const settingsDefault = {
    dots: false,
    draggable: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: false,
    // autoplaySpeed: 5000,
    pauseOnHover: true,
    className: 'carousel-portfolios',
  };

  const newSettings = {
    ...settingsDefault,
    ...settings,
  };

  return (
    <div className="my-carousel-v2" hidden={!portfolios?.length}>
      <div className={classNames({ container: hasContainer })}>
        <Heading title={title} subtitle={subtitle} />
        <LazyLoad height={md ? 560 : 582} offset={500}>
          <Carousel {...newSettings}>
            {portfolios.map(
              (portfolio, index) =>
                index < limitPortfolio && (
                  <Fragment key={index}>
                    <div className="block-portfolio">
                      <div className="block-portfolio__thumbnail">
                        {portfolio.thumbnail ? (
                          <Image
                            src={portfolio.thumbnail}
                            alt={altSchema.map((item) => portfolio[item] || item).join(' ')}
                            layout="fill"
                            className="image"
                            objectFit="contain"
                          />
                        ) : (
                          <Skeleton.Image className="image" />
                        )}
                      </div>

                      <div className="block-portfolio__meta">
                        <div className="block-portfolio__thumbnail">
                          {portfolio.thumbnail ? (
                            <Image
                              src={portfolio.thumbnail}
                              alt={altSchema.map((item) => portfolio[item] || item).join(' ')}
                              layout="fill"
                              className="image"
                              objectFit="contain"
                            />
                          ) : (
                            <Skeleton.Image className="image" />
                          )}
                        </div>
                        <div className="block-portfolio__subtitle">
                          {portfolio.subtitle || (
                            <Skeleton active paragraph={{ rows: 4 }} className="block-portfolio__subtitle" />
                          )}
                        </div>
                        <div className="block-portfolio__title">{portfolio.title}</div>
                        <div
                          className="block-portfolio__description"
                          dangerouslySetInnerHTML={{
                            __html: portfolio.description,
                          }}
                        />
                        <div className="block-portfolio__action">
                          {portfolio.link ? (
                            <Link href={`/portfolios/${portfolio.link}`}>
                              <a>
                                <ButtonLearnMore
                                  className="block-portfolio__learn-more"
                                  text={t('common.txtExploreNow')}
                                  icon={
                                    <img
                                      className="icon-arrow-thin"
                                      src="/icons/right-arrow-blue-2.svg"
                                      alt="Right Arrow"
                                    />
                                  }
                                />
                              </a>
                            </Link>
                          ) : (
                            <Skeleton.Button active className="button-learn-more" />
                          )}
                          {/* <div className="button-prev-next">
                            <Button icon={<AngleRight />} />
                            <Button icon={<AngleRight />} />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ),
            )}
          </Carousel>
        </LazyLoad>
      </div>
    </div>
  );
};

export default MyCarousel;
